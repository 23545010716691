<template>
	<!-- 用气分析 -->
	<div style="height: 100%;" ref="install" id="sevenDays"></div>
</template>

<script>
	import * as echarts from 'echarts';
	export default{
		data(){
			return {
				data:{
					businessOutletsId:'',
					businessOutletsId:'',
				}
			}
		},
		mounted(){
			this.unit();
		},
		methods:{
			unit(){
				var myChart = echarts.init(document.getElementById('sevenDays'));
				let xLabel = [];
				let L0 = [ 18 , 11 , 13 , 9 , 27 , 17 , 0 ];
				let L1 = [];
				this.$api.screen_alarmNum().then( res => {
					if( res.code == 200 ){
						for( var i = 0 ; i < res.data.length ; i++ ){
							xLabel.push( res.data[i].day.split("-")[1] + '/' + res.data[i].day.split("-")[2] );
							// L0.push( res.data[i].num );
						}
						console.log( L0 )
						var that = this;
						setTimeout(function(){
							that.unit()
						},60000)
						var option = {
								tooltip: {
									trigger: 'axis',
								},
								legend: {
									align: 'left',
									right: '30px',
									type: 'plain',
									top: '10px',
									textStyle: {
										color: 'rgba(77,192,36,1)',
										fontSize: 12,
									},
									itemGap: 25,
									itemWidth: 18,
								},
								grid: {
									top: '50px',
									left: '80px',
									right: '40px',
									bottom: '15%',
								},
								xAxis: [
									{
										type: 'category',
										boundaryGap: false,
										axisLine: {
											//坐标轴轴线相关设置。数学上的x轴
											show: true,
											lineStyle: {
												color: '#FFB100',//x轴
											},
										},
										axisLabel: {
											//坐标轴刻度标签的相关设置
											textStyle: {
												color: '#ffffff',
												padding: 10,
												fontSize: 12,
											},
											formatter: function (data) {
												return data;
											},
										},
										splitLine: {
											show: true,
											lineStyle: {
												color: '#6d735b',//竖纵线
											},
										},
										axisTick: {
											show: false,
										},
										data: xLabel,
									},
								],
								yAxis: [
									{
										name: '次数',
										nameTextStyle: {
											color: '#ffffff',//纵坐标意思
											fontSize: 16,
											padding: 10,
											fontWeight:'bold'
										},
										min: 0,
										splitLine: {
											show: true,
											lineStyle: {
												color: '#6d735b',//横坐标线
											},
										},
										axisLine: {
											show: true,
											lineStyle: {
												color: '#FFB100',
											},
										},
										axisLabel: {
											show: true,
											textStyle: {
												color: '#ffffff',
												padding: 10,
												fontWeight:'bold',
												fontSize:'14px'
											},
											formatter: function (value) {
												if (value === 0) {
													return value;
												}
												return value;
											},
										},
										axisTick: {
											show: false,
										},
									},
								],
								series: [
									{
										name: '报警次数',
										type: 'line',
										symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
										showAllSymbol: true,
										symbolSize: 0,
										smooth: true,
										lineStyle: {
											normal: {
												width: 2,
												color: 'rgba(77,192,36,1)', // 线条颜色
											},
										},
										itemStyle: {
											color: 'rgba(77,192,36,1)',
										},
										tooltip: {
											show: true,
										},
										areaStyle: {
											//区域填充样式
											normal: {
												//线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
												color: new echarts.graphic.LinearGradient(
													0,
													0,
													0,
													1,
													[
														{
															offset: 0,
															color: 'rgba(77,192,36,.3)',
														},
														{
															offset: 1,
															color: 'rgba(77,192,36, 0)',
														},
													],
													false
												),
												shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
												shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
											},
										},
										data: L0,
									}
								],
							};
							myChart.setOption(option);
					}
				}).catch(err=>{

				})
			}
		}
	}
</script>

<style>
</style>
