<template>
	<div class="home_main" style="overflow: hidden;user-select: none;">
		<div class="heade">
			<el-row style="height: 100%;">
				<el-col style="height: 100%;" :span="7">
					<el-row :gutter="25" type="flex" align="middle" style="height: 100%;">
						<el-col :span="2"></el-col>
						<el-col :span="18" style="font-size: 12px;">
							<el-row :gutter="25" type="flex" align="middle" style="height: 100%;color: #419aff;">
								<span style="font-size: 30px;letter-spacing: 2px;font-weight: bold;">{{ timer_str_2 }}</span>
								<span style="margin: 0 8px;display: flex;flex-direction: column;">
									<p v-show=" timer_str_3 != '' ">{{ timer_str_3 | timer_str }}</p>
									<p>{{ timer_str_1 }}</p>
								</span>
								<!-- <img v-show=" timer_str_3 != '' " width="35" src="../../assets/tianqi.png" > -->
							</el-row>
						</el-col>
					</el-row>
				</el-col>
				<el-col style="height: 100%;background: linear-gradient(90deg,#021631 , #6b6be4 , #021631);position: relative;" :span="10" >						
					<dv-decoration-3 style="width:200px;height: 20px;position:absolute;top:24px" />
					<div class="logo">前锋燃气安全卫士</div>
					<dv-decoration-3 style="width:200px;height: 20px;position:absolute;top:24px;right: 0px;" />
				</el-col>
				<el-col style="height: 100%;" :span="7">
					<div class="menu">
						<dv-decoration-6 style="width:200px;height: 20px;" />
						<span @click="nav( item )" v-for=" ( item , i ) in menu" :key="i">
							<img :src="item.icon">	{{ item.title }}
						</span>
						<dv-decoration-6 style="width:200px;height: 20px;" />
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="main">
			<el-row style="height: 100%;">
				<el-col style="height: 100%;" :span="6">
					<div class="home_left">
						<div class="overview pd_b">
							<h4 class="title" style="margin-bottom: 10px;">
								设备总览
							</h4>
							<dv-border-box-9 style="height: 100px;">
								<el-row :gutter="10" class="overview_box">
									<el-col :span="6" >
										<dv-border-box-13 class="overview_item" style="height:60px;width:100px">
											<div class="overview_item_title">用户数</div>
											<div class="overview_item_number"><countTo  :startVal='0' :endVal='10067' :duration='2000'></countTo></div>
										</dv-border-box-13>
									</el-col>
									<el-col :span="6" >
										<dv-border-box-13 class="overview_item" style="height:60px;width:100px">
											<div class="overview_item_title">设备数</div>
											<div class="overview_item_number"><countTo  :startVal='0' :endVal='13612' :duration='2000'></countTo></div>
										</dv-border-box-13>
									</el-col>
								</el-row>
							</dv-border-box-9>
						</div>
						<div class="pd_b" style="flex: 2">
							<div class="pd_b" style="flex: 1;display: flex;flex-direction: column;">
								<h4 class="title">设备数量柱状图</h4>
								<div style="flex: 1;" class="pd_t">
									<installList ref="installList" />
								</div>
							</div>
							
							<div class="pd_b" style="flex: 1;display: flex;flex-direction: column;">
								<h4 class="title">厨电设备状态环形图</h4>
								<div style="height: 100%;" class="pd">
									<sellType ref="child2"></sellType>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col style="height: 100%;" :span="12">
					<div class="home_content">
						<div style="flex: 2;">
							<charts-map @update="map_update" :installList="installList_map" ref="chartsMap" />
						</div>
						<div class="pd_b" style="height: 200px;">
							<h4 class="title">报警消息</h4>
							<div ref="excessiveRankList_TOP5_box" style="height: 100%;margin-bottom: 5px;">
								<scroll :data="yesterdayAlarms" :class-option="scrollOption" style="overflow: hidden;" :style="{ height : ( excessiveRankList_TOP5_H ) + 'px' }">
									<dv-border-box-8 style="padding: 10px;box-sizing: border-box;border-radius: 4px;font-size: 13px;" v-for="( item , i ) in yesterdayAlarms" :key="i">
										<el-row>
											<el-col :span="22" style="display: flex;flex-direction: row;align-items: center;margin-top: 6px;">
												<img style="margin-right: 5px;" width="20" src="../../assets/setting.png">
												设备编号：{{item.deviceNo}}
											</el-col>
										</el-row>
										<el-row >
											<el-col :span="22" style="display: flex;flex-direction: row;align-items: center;margin-top: 6px;">
												<img style="margin-right: 5px;" width="20" src="../../assets/CH4.png">
												<span>甲烷(CH4)：{{item.ch4State}}(浓度:{{item.ch4Concentration}}%LEL)</span>
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="22" style="display: flex;flex-direction: row;align-items: center;margin-top: 6px;">
												<img style="margin-right: 5px;" width="20" src="../../assets/CO.png">
												<span>一氧化碳(CO)：{{item.coState}}(浓度:{{item.coConcentration}}%PPM)</span>
											</el-col>
										</el-row>
										<el-row style="padding-bottom: 10px;">
											<el-col :span="18" style="display: flex;flex-direction: row;align-items: center;margin-top: 6px;">
												<img style="margin-right: 5px;" width="20" src="../../assets/alarmMessage.png">
												<span>报警消息内容：{{item.content}}</span>
											</el-col>
											<el-col :span="6" style="display: flex;flex-direction: row;align-items: center;margin-top: 6px;color: #ff0000;">
												<img style="margin-right: 5px;" width="20" src="../../assets/alarmTime.png">
												报警时间：{{item.createTime}}
												</el-col>
										</el-row>
									</dv-border-box-8>
								</scroll>
							</div> 
						</div>
					</div>
				</el-col>
				<el-col style="height: 100%;" :span="6">
					<div class="home_right">
						<div class="pd_b" style="display: flex;flex-direction: column;">
							<div style="flex: 1;">
								<h4 class="title">设备状态</h4>
								<div ref="excessiveRankList_TOP5_box2" style="margin-bottom: 5px;">
									<div style="padding-bottom: 5px;text-align: center;">烟感设备</div>
									<scroll :data="alarmData" :class-option="scrollOption" @scrollEnd="scrollEnd1" style="overflow: hidden;" :style="{ height : '136px' }">
										<dv-border-box-8 style="padding: 10px;box-sizing: border-box;border-radius: 4px;font-size: 13px;"  v-for="( item , i ) in alarmData" :key="i">
											<el-row>
												<el-col :span="10">设备编号：<span style="color: #FF3FDA;">{{item.deviceNo}}</span></el-col>
												<el-col :span="8">设备类型：{{item.typeName}}</el-col>
												<el-col :span="6" style="color: #0FE6FA;">设备品牌：<span>{{item.deviceBrand}}</span></el-col>
											</el-row>
											<el-row>
												<el-col :span="18">设备ID：{{item.deviceId}}</el-col>
											</el-row>
											<el-row>
												<el-col :span="18">最后上报时间：{{item.lastUploadTime}}</el-col>
												<el-col :span="6">
													<span>状态：
														<span v-if="item.statusText=='—'" style="color: #fad104;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='报警'" style="color: #E43D30;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='正常'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='在线-关机'" style="color: #D51BB2;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='在线-开机'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='在线'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
														<span v-if="item.statusText=='离线'" style="color: lightgray;font-weight:bold">{{item.statusText}}</span>
													</span>
												</el-col>
											</el-row>
										</dv-border-box-8>
									</scroll>
								</div> 
								<div ref="excessiveRankList_TOP5_box3" style="margin-bottom: 5px;">
									<div style="padding-bottom: 5px;text-align: center;">厨电设备</div>
									<scroll :data="noAlarmData" :class-option="scrollOption" style="overflow: hidden;" :style="{ height : '136px' }">
										<dv-border-box-8 style="padding: 10px;box-sizing: border-box;border-radius: 4px;font-size: 13px;"  v-for="( item , i ) in noAlarmData" :key="i">
											<el-row>
												<el-col :span="10">设备编号：<span style="color: #FF3FDA;">{{item.deviceNo}}</span></el-col>
												<el-col :span="8">设备类型：{{item.typeName}}</el-col>
												<el-col :span="6" style="color: #0FE6FA;">设备品牌：{{item.deviceBrand}}</el-col>
											</el-row>
											<el-row>
												<el-col :span="18">设备ID：{{item.deviceId}}</el-col>
											</el-row>
											<el-row>
												<el-col :span="18">最后上报时间：{{item.lastUploadTime}}</el-col>
												<el-col :span="6">状态：
														<span v-if="item.deviceTypeCode==0">
															<span v-if="item.statusText=='—'" style="color: #fad104;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='报警'" style="color: #E43D30;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='正常'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
														</span>
														<span v-if="item.deviceTypeCode==2||item.deviceTypeCode==3||item.deviceTypeCode==4||item.deviceTypeCode==5">
														 	<span v-if="item.statusText == '—'" style="color: #fad104">—</span>
															<span v-if="item.statusText == '在线-关机'||item.statusText == '在线-开机'||item.statusText =='在线'" style="color: #48DD12">在线</span>
														 	<span v-if="item.statusText == '离线'" style="color: lightgray">离线</span>
														</span>
														<span v-if="item.deviceTypeCode==6">
														 	<span style="color: #48DD12">正常</span>
														</span>
														<span v-if=" item.deviceTypeCode == 1 || item.deviceTypeCode == 7 || item.deviceTypeCode == 8 ">
															<span v-if="item.statusText=='—'" style="color: #fad104;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='在线-关机'" style="color: #D51BB2;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='在线-开机'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='在线'" style="color: #48DD12;font-weight:bold">{{item.statusText}}</span>
															<span v-if="item.statusText=='离线'" style="color: lightgray;font-weight:bold">{{item.statusText}}</span>
														</span>
												</el-col>
											</el-row>
										</dv-border-box-8>
									</scroll>
								</div>
							</div>
						</div>
						<div class="pd_b">
							<h4 class="title">近7天报警次数</h4>
							<useGasAnalyse ref="rightTop"></useGasAnalyse>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import { TextToCode } from 'element-china-area-data';
	import countTo from 'vue-count-to';
	import scroll from 'vue-seamless-scroll';
	import chartsMap from './components/charts-map.vue';
	// 左柱
	import installList from './components/installList.vue';
	// 左扇
	import sellType from './components/left-bottom.vue'
	import useGasAnalyse from './components/right-top.vue'
	export default{
		inject: ['reload'],
		computed: {
			scrollOption () {
				return {
					step: 3, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.list.length+1
					// hoverStop: true, // 是否开启鼠标悬停stop
					// direction: 1, // 0向下 1向上 2向左 3向右
					// openWatch: true, // 开启数据实时监控刷新dom
					// singleHeight: 21, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					// singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					// waitTime: 2500 // 单步运动停止的时间(默认值1000ms)
				}
			}
		},
		data(){
			return {
				show_monitoring_flag : false,
				timer_str_1 : "",
				timer_str_2 : "",
				timer_str_3 : "",
				timer : null,
				deviceGeneralData:{},
				deviceZhu:[{
						area:'烟感设备',
						installNum:''
					},{
						area:'热水器',
						installNum:''
					},{
						area:'壁挂炉',
						installNum:''
					},{
						area:'抽烟机',
						installNum:''
					},{
						area:'灶具',
						installNum:''
					},{
						area:'燃气表',
						installNum:''
					}],
				config:{
					radius: '88%',
					  activeRadius: '45%',
					  data: [
						
					  ],
					  digitalFlopStyle: {
						fontSize: 20
					  },
					  showOriginValue: true
				},
				yesterdayAlarms:[],
				excessiveRankList_TOP5_H : 0 ,
				menu : [
					{ title : "进入系统" , icon : require( '@/assets/screen_xt.png' ) , url : "" },
					{ title : "退出" , icon : require( '@/assets/tuichu.png' ) , url : "" },
				],
				alarmData:[],
				noAlarmData:[],
				otherNum:[{
						area:'在线数量',
						installNum:''
					},{
						area:'离线数量',
						installNum:''
					},{
						area:'其他数量',
						installNum:''
					},{
						area:'开机数量',
						installNum:''
					},{
						area:'关机数量',
						installNum:''
					}],
				userTotalData:[],
				userPage:1,
				userStatus:0,
				xTimer1:'',
				//地图数据
				installList_map : [],
				form_data : {
					province : { cityName : "" , code : "" },
					city : { cityName : "" , code : "" },
					district : { cityName : "" , code : "" },
				},
				drawer1:false,
				drawer2:false,
				
				
				cfyesterDayAlarm:''
			}
		},
		mounted(){ 
			this.excessiveRankList_TOP5_H = this.$refs.excessiveRankList_TOP5_box.offsetHeight - 60;
		},
		created(){
			this.userDatas();
			this.get_screen_data();
			this.alarmDatas(1)
			this.noAlarmDatas(1);
			this.timekeeping();
			this.cfyesterDayAlarm = setInterval( () => {
				this.yesTer();
			} , 10000 )
		},
		methods:{
			scrollEnd1(){
			},
			nav( menu_item ){
				let path = menu_item.url;
				clearInterval(this.cfyesterDayAlarm);
				this.cfyesterDayAlarm = "";
				if( menu_item.title == "进入系统" ){
					path = this.menuList[0].children[0].path;
					this.xTimer1 = "1"
				}
				if( menu_item.title == "退出" ){
					this.xTimer1 = "1"
					this.$router.replace({ path : '/login' });
					this.$vuex( 'user_info' , "" );
					return
				}
				this.$router.push({
					path,
				});
			},
			timekeeping(){
				this.timer = setInterval( () => {
					this.timer_str_1 = dayjs().format('YYYY.MM.DD');
					this.timer_str_2 = dayjs().format('HH:mm:ss');
					this.timer_str_3 = dayjs().format('d');
				} , 1000 );
			},
			async get_screen_data( type ){
				let res = await this.$api.screen_deviceGeneral()
				this.deviceGeneralData = res.data;
				// this.deviceZhu[0].installNum = this.deviceGeneralData.alarmNum;
				// this.deviceZhu[1].installNum = this.deviceGeneralData.waterHeaterNum;
				// this.deviceZhu[2].installNum = this.deviceGeneralData.wallHangingStoveNum;
				// this.deviceZhu[3].installNum = this.deviceGeneralData.kitchenHoodNum;
				// this.deviceZhu[4].installNum = this.deviceGeneralData.stoveNum;
				// this.deviceZhu[5].installNum = this.deviceGeneralData.gasMeterNum;
				this.deviceZhu[0].installNum = 4094;
				this.deviceZhu[1].installNum = 3038;
				this.deviceZhu[2].installNum = 1015;
				this.deviceZhu[3].installNum = 1198;
				this.deviceZhu[4].installNum = 2116;
				this.deviceZhu[5].installNum = 991;
				this.$refs.installList.set_option( this.deviceZhu );
				this.otherNum[0].installNum = 4138;//在线
				this.otherNum[1].installNum = 5118;//离线
				this.otherNum[2].installNum = 3000;//其他
				this.otherNum[3].installNum = 879;//开机
				this.otherNum[4].installNum = 324;//关机
				this.$refs.child2.set_option( this.otherNum );
				this.yesTer();
				var thats = this;
				if( this.xTimer1 == "" ){
					setTimeout( function(){ thats.get_screen_data( 1 ) } , 60000 )
				}
			},
			//地图切换
			map_update( e ){
				this.form_data = e;
				this.userDatas( 'update' );
			},
			async userDatas( type ){
				//组装数据
				let form_data = { province : "" , city : "" , district : "" };
				for( let k in this.form_data ){
					form_data[k] = this.form_data[k].cityName;
				}
				if( form_data.province == "北京市" || form_data.province == "天津市" || form_data.province == "上海市" || form_data.province == "重庆市" ){
					form_data.city = "市辖区"
				}
				let screen_list_res = await this.$api.screenHomeNum( form_data );
				if( screen_list_res.code == 200 ){
					//处理地图数据
					this.installList_map = screen_list_res.data;
					//更新地图
					if( type ){
						if( this.form_data.district.cityName != "" ){
							this.$refs.chartsMap.getGeoJson( this.form_data.district.code );
						}else if( this.form_data.city.cityName != "" ){
							this.$refs.chartsMap.getGeoJson( this.form_data.city.code );
						}else if( this.form_data.province.cityName != "" ){
							this.$refs.chartsMap.getGeoJson( this.form_data.province.code );
						}else{
							this.$refs.chartsMap.getGeoJson( 100000 );
						}
					}else{
						this.$refs.chartsMap.getGeoJson( this.$refs.chartsMap.textToCode() );
					}
				}else{
					this.$refs.chartsMap.getGeoJson( 100000 );
				}
			},
			noAlarmDatas(esRd){
				var data = {
					startPage : esRd,
					pageSize : 10,
					type:1
				}
				this.$api.screen_deviceStatus(data).then( res => {
					if( res.code == 200 ){
						if( data.startPage <= res.data.totalPage ){
							this.noAlarmData = this.noAlarmData.concat(res.data.list);
							for( var i = 0 ; i < this.noAlarmData.length ; i++ ){
								if( this.noAlarmData[i].deviceTypeCode == "0" ){
									if( this.noAlarmData[i].status == 0 ){
										this.noAlarmData[i].statusText = "报警"
									}else if( this.noAlarmData[i].status == 1 ){
										this.noAlarmData[i].statusText = "正常"
									}else{
										this.noAlarmData[i].statusText = "—"
									}
								}else{
									if( this.noAlarmData[i].status == 0 ){
										this.noAlarmData[i].statusText = "在线-关机"
									}else if( this.noAlarmData[i].status == 1 ){
										this.noAlarmData[i].statusText = "在线-开机"
									}else if( this.noAlarmData[i].status == -1 ){
										this.noAlarmData[i].statusText = "在线"
									}else if( this.noAlarmData[i].status == -2 ){
										this.noAlarmData[i].statusText = "离线"
									}else{
										this.noAlarmData[i].statusText = "—"
									}
								}
							}
							data.startPage++
							if( this.xTimer1 == "" ){
								setTimeout( () => { 
									if( data.startPage > res.data.totalPage ){
										this.noAlarmData = [];
										this.noAlarmDatas( 1 ); 
									}else{
										this.noAlarmDatas( data.startPage ); 
									}
								} , 6000 )
							}
						}
					}
				});
			},
			alarmDatas(esRd){
				var data = {
					startPage : esRd,
					pageSize : 10,
					type:0
				}
				this.$api.screen_deviceStatus(data).then( res => {
					if( res.code == 200 ){
						if( data.startPage <= res.data.totalPage ){
							this.alarmData = this.alarmData.concat(res.data.list);
							for( var i = 0 ; i < this.alarmData.length ; i++ ){
								if( this.alarmData[i].deviceTypeCode == "0" ){
									if( this.alarmData[i].status == 0 ){
										this.alarmData[i].statusText = "报警"
									}else if( this.alarmData[i].status == 1 ){
										this.alarmData[i].statusText = "正常"
									}else{
										this.alarmData[i].statusText = "—"
									}
								}else{
									if( this.alarmData[i].status == 0 ){
										this.alarmData[i].statusText = "在线-关机"
									}else if( this.alarmData[i].status == 1 ){
										this.alarmData[i].statusText = "在线-开机"
									}else if( this.alarmData[i].status == -1 ){
										this.alarmData[i].statusText = "在线"
									}else if( this.alarmData[i].status == -2 ){
										this.alarmData[i].statusText = "离线"
									}else{
										this.alarmData[i].statusText = "—"
									}
								}
							}
							data.startPage++
							if( this.xTimer1 == "" ){
								setTimeout( () => { 
									if( data.startPage > res.data.totalPage ){
										this.alarmData = [];
										this.alarmDatas( 1 ); 
									}else{
										this.alarmDatas( data.startPage ); 
									}
								} , 6000 )
							}
						}
					}
				});
			},
			yesTer(){
				this.$api.screen_yesterdayAlarm().then( res => {
					if( res.code == 200 ){
						this.yesterdayAlarms = res.data.list
					}
				});
			},
		},
		components:{
			countTo,scroll,installList,sellType,useGasAnalyse,chartsMap
		},
		beforeRouteLeave( to, from, next ){
			this.$refs.installList.dispose();
			clearTimeout( this.timer );
			next();
		},
		filters:{
			status_filter( status ){
				var status_text = ""
				if( status.split("|")[1] == '0' ){
					if( status.split("|")[0] == 'null' ){
						status_text = "—";
					}else if( status.split("|")[0] == '0' ){
						status_text = "报警";
					}else if( status.split("|")[0] == '1' ){
						status_text = "正常";
					}
				}else{
					if( status.split("|")[0] == 'null' ){
						status_text = "—";
					}else if( status.split("|")[0] == '0' ){
						status_text = "在线-关机";
					}else if( status.split("|")[0] == '1' ){
						status_text = "在线-开机";
					}else if( status.split("|")[0] == '-1' ){
						status_text = "在线";
					}else if( status.split("|")[0] == '-2' ){
						status_text = "离线";
					}
				}
				return status_text;
			},
			timer_str( str ){
				if( str == 0 ){
					return "星期天";
				}else if( str == 1 ){
					return "星期一";
				}else if( str == 2 ){
					return "星期二";
				}else if( str == 3 ){
					return "星期三";
				}else if( str == 4 ){
					return "星期四";
				}else if( str == 5 ){
					return "星期五";
				}else if( str == 6 ){
					return "星期六";
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.border_status_0{
		border-left:2px solid #FFE266;
		border-right:2px solid #FFE266;
	}
	.border_status_1{
		border-left:2px solid #67C23A;
		border-right:2px solid #67C23A;
	}
	.border_status_2{
		border-left:2px solid #F56C6C;
		border-right:2px solid #F56C6C;
	}
	.border_status_3{
		border-left:2px solid #909399;
		border-right:2px solid #909399;
	}
	.border_status_4{
		border-left:2px solid #FF2700;
		border-right:2px solid #FF2700;
	}
	.home_main {
		// background: url(../../assets/bg_2.png);
		color: #fff;
		padding: 0px;
		height: 100vh;
		box-sizing: border-box;
		background-color: #021132;
		display: flex;
		flex-direction: column;
		background-size: cover;
		background-position: center center;
	}
	
	.title{
		width: 270px;
		font-size: 14px;
		margin-bottom: 5px;
		height: 50px;
		line-height: 50px;
		background-image: url(../../assets/title.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		box-sizing: border-box;
		position: relative;
		text-align: center;
	}
	.heade {
		height: 60px;
		background-size: cover;
	 	background-position: center;
		.time{
			background-color: #043077;
		}
		.logo{
			display: inline-block;
			width: 100%;
			height: 70px;
			line-height: 55px;
			margin: 0 auto;
			align-items: center;
			box-sizing: border-box;
			background-image: url(../../assets/top_bg.png);
			background-size: 100% 100%;
			text-align: center;
			font-size: 34px;
			font-weight: bold;
		}
		.menu{
			color: #419AFF;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			font-size: 13px;
			text-align: right;
			span{
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					width: 20px;
					height: 20px;
					margin-right: 6px;
				}
				img:nth-child(2){
					width: 20px;
					height: 20px;
				}
				cursor: pointer;
			}
		}
	}
	.main {
		flex: 1;
		.home_left {
			display: flex;
			flex-direction: column;
			height: 100%;
			> div {
				// flex: 1;
				display: flex;
				flex-direction: column;
			}
			.overview{
				font-size: 13px;
				display: flex;
				flex-direction: column;
				.overview_box{
					display: flex;
					align-items: center;
					flex-direction: row;
					flex-wrap: wrap;
					flex: 1;
					height: 100%;
					padding: 15px;
					justify-content: space-around;
					.overview_item{
						height: 100%;
						border-radius: 4px;
						text-align: center;
						display: flex;
						flex-direction: column;
						.overview_item_number{
							// flex: 1;
							color: #CCFF33;
							font-size: 21px;
							font-weight: bold;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.overview_item_title{
							color: #66FFFF;
							flex: 1;
							font-size: 13px;
							display: flex;
							align-items: center;
							justify-content: center;
							padding-top: 10px;
						}
					}
				}
			}
		}
		.home_content {
			padding: 0 10px;
			display: flex;
			flex-direction: column;
			height: 100%;
			>div {
				flex: 1;
			}
		}
		
		.home_right {
			display: flex;
			flex-direction: column;
			height: 100%;
			>div {
				flex: 1;
			}
		}
	} .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .userInfoText{
		color:#FFE000;
		font-size:17px;
		width:164px;
		font-weight:bold;
		position: absolute;
		top: 75px;
		text-align: center;
		// left: 47px;
	} 
	.userInfoTitle{
		color:#FFE000;
		font-size:17px;
		width:164px;
		font-weight:bold;
		position: absolute;
		top: 69px;
		text-align: center;
	}
	.el-carousel__container{
		
	}
	.dataDeviceUser{
		margin-bottom: 5px;
		// background-size: cover;
	}
	.userInfoBold{
		 height:100%;
		 flex: 1;
		.userLeft{
			display: flex;
			flex-direction: column;
			height: 100%;
			>div {
				flex: 1;
			}
		}
		.userCenter{
			display: flex;
			flex-direction: column;
			height: 100%;
			padding-top: 65px;
			>div {
				flex: 1;
			}
		}
		.userRight{
			display: flex;
			flex-direction: column;
			height: 100%;
			>div {
				flex: 1;
			}
		}
	}
	.infoCenter{
		padding-left: 10px;
		border-radius: 15px;
		position: relative;
	}
	.deviceImg{
		position: absolute;
		right: 20px;
		top: 35px;
	}
	
</style>
