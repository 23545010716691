<template>
	<div class="echarts">
		<div v-show="!showMap" style="width:100;height:100%" ref="dotMap"></div>
		<qfMap v-if="showMap" ref="qfMap" :overview="false" :city_list="false" :panorama="false" @show_monitoring="show_monitoring" :map_style="true" :list="list" style="width: 100%;height: 97%;margin-top: 2%;" />
		<div class="mapChoose">
			<span v-for="(item,index) in parentInfo" :key="item.code" style="display: flex;flex-direction: row;align-items: center;">
				<dv-border-box-12 style="width: 100px;height: 40px;text-align: center;text-align: center;line-height: 40px;">
					<span class="title" @click="chooseArea(item,index)">{{item.cityName=='全国'?'中国':item.cityName}}</span>
				</dv-border-box-12>
				<span class="icon" v-show="index+1!=parentInfo.length">></span>
			</span>
		</div>
		<div class="monitoring_box" v-if=" show_monitoring_info_index != -1 " :style="{ 'height' : show_monitoring_flag ? '676px' : 0 }">
			<div class="monitoring">
				<div class="titlel bg_5" style="position: relative;">
					{{ homeData.name }}
					<i @click=" repaireIndex " class="el-icon-close"></i>
				</div>
				<div class="data_list" style="height: 300px;overflow: auto;background-color: #141D39;border: 1px solid #28477F;">
					<div class="data_list_item" style="font-size: 13px;">
						 {{ homeData.address }}
					</div>
					<div class="data_list_item" v-if="homeDeviceList.length != 0" v-for="( item , i ) in homeDeviceList" :key="i">
						<img style="width: 50px; height: 50px" src="../../../assets/cejuqiuji.png" v-if=" item.deviceTypeCode == 0 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/reshuiqi.png" v-if=" item.deviceTypeCode == 1 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/bigualu.png" v-if=" item.deviceTypeCode == 2 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/dianlifuzai-power_load-fill.png" v-if=" item.deviceTypeCode == 3 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/canyinyouyan.png" v-if=" item.deviceTypeCode == 4 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/ranqizao.png" v-if=" item.deviceTypeCode == 5 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/zanwuranqibiao.png" v-if=" item.deviceTypeCode == 6 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/bufentubiao_jishu-32.png" v-if=" item.deviceTypeCode == 7 "></img>
						<img style="width: 50px; height: 50px" src="../../../assets/gongre2.png" v-if=" item.deviceTypeCode == 8 "></img>
						<div style="padding-left: 5px;font-size: 12px;">
							<div class="p">设备类型：{{ item.typeName }}</div>
							<div class="p">品牌：{{ item.deviceBrand }}</div>
							<div class="p">设备编号 : {{item.deviceNo}} </div>
							<div class="p">设备状态 : 
								<span class="" v-if=" item.deviceTypeCode == 0 ">
									<span class="deviceCss" v-if=" item.status == null " style="color:#ffffff">/</span>
									<span class="deviceCss" v-if=" item.status == 0 " style="color:#f40000">报警</span>
									<span class="deviceCss" v-if=" item.status == 1 " style="color:#00aa00">正常</span>
								</span>
								<span class="" v-if=" item.deviceTypeCode != 0 && item.deviceTypeCode != 6 ">
									<span class="deviceCss" v-if=" item.status == null " style="color:#ffffff">/</span>
									<span class="deviceCss" v-if=" item.status == 0 " style="color:#f48b0a">在线-关机</span>
									<span class="deviceCss" v-if=" item.status == 1 " style="color:#00aa00">在线-开机</span>
									<span class="deviceCss" v-if=" item.status == -1 " style="color:#1461EB">在线</span>
									<span class="deviceCss" v-if=" item.status == -2 " style="color:#b4b4b4">离线</span>
								</span>
								<span class="" v-if=" item.deviceTypeCode == 6 ">
									<span class="deviceCss" v-if=" item.status == null " style="color:#ffffff">/</span>
									<span class="deviceCss" v-if=" item.status == 0 " style="color:#00aa00">开阀</span>
									<span class="deviceCss" v-if=" item.status == 1 " style="color:#b4b4b4">关阀</span>
								</span>
							</div>
						</div>
					</div>
					<div v-if="homeDeviceList.length == 0" style="color: #ffffff;text-align: center;font-weight: bold;border-top: 1px solid #28477F;font-size:13px;">暂无设备绑定</div>
				</div>
			</div>
		</div>
		<div class="monitoring_box2" v-if=" show_monitoring_info_index != -1 " :style="{ 'height' : show_monitoring_flag ? '676px' : 0 }">
			<div class="monitoring">
				<div class="titlel bg_5">家庭成员<i @click=" repaireIndex " class="el-icon-close"></i></div>
				<div class="data_list" style="height: 300px;overflow: auto;background-color: #141D39;border: 1px solid #28477F;">
					<div class="data_list_item" v-for="( item , i ) in homeFamilyList" :key="i">
						<img style="width: 50px; height: 50px;border-radius: 50%;border: 2px solid #e1e1e1;" :src="item.avatarUrl == '' ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : item.avatarUrl" fit="fill"></img>
						<div style="padding-left:5px;">
							<div class="p">用户昵称：{{ item.nickName }}</div>
							<div class="p">成员类型：{{ item.creator == 1 ? "创建者" : "成员" }}</div>
							<div class="p">电话号码：{{ item.phone }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//测试
	const province = "四川省";
	const city = "成都市";
	const district = "金牛区";
	const level = 1;

	import axios from 'axios';
	import * as echarts from 'echarts';
	import { TextToCode } from 'element-china-area-data';
	import qfMap from '@/components/qf-map.vue';
	var myChart = null
	export default {
		name: 'dotMap',
		data() {
			return {
				timer : "",
				city_index : -1,
				geoJson: {
					features: []
				},
				parentInfo: [{
					cityName: "全国",
					code: 100000
				}],
				showMap: false,
				list: [],
				address:{
					province : "",
					city : "",
					district : "",
				},
				show_monitoring_info_index:-1,
				homeData:{},
				homeDeviceList:[],
				homeFamilyList:[],
				show_monitoring_flag : false,
			}
		},
		// created() {
		// 	this.textToCode();
		// 	// this.getGeoJson( this.textToCode() );
		// },
		methods: {
			dispose(){
				if( myChart != null ){
					clearInterval( this.timer );
					myChart.dispose();
					myChart = null;
				}
			},
			gaoliang( data ){
				this.city_index = -1;
				clearInterval( this.timer );
				if( data.length == 1 ){
					return;
				}
				this.timer = setInterval( () => {
				    // 隐藏提示框
				    myChart.dispatchAction({
				        type: 'hideTip',
				        seriesIndex: 0,
				        dataIndex: this.city_index
				    });
				    // 显示提示框
				    myChart.dispatchAction({
				        type: 'showTip',
				        seriesIndex: 0,
				        dataIndex: this.city_index + 1
				    });
				    // 取消高亮指定的数据图形
				    myChart.dispatchAction({
				        type: 'downplay',
				        seriesIndex: 0,
				        dataIndex: this.city_index
				    });
				    // 高亮指定的数据图形
				    myChart.dispatchAction({
				        type: 'highlight',
				        seriesIndex: 0,
				        dataIndex: this.city_index + 1
				    });
				    this.city_index++;
				    if ( this.city_index > data.length ) {
				        this.city_index = -1;
				    }
				}, 2500 );
			},
			textToCode() {
				if ( this.address.province != "" && this.address.city == "" && this.address.district == "" ) {						//省
					this.parentInfo.push({
						cityName: this.address.province,
						code: parseInt(TextToCode[ this.address.province].code),
					});
					return TextToCode[ this.address.province ].code;
				} else if ( this.address.province != "" && this.address.city != "" && this.address.district == "" ) {				//市
					this.parentInfo = [...this.parentInfo, {
						cityName: this.address.province,
						code: parseInt(TextToCode[this.address.province].code)
					}, {
						cityName: this.address.city,
						code: parseInt(TextToCode[ this.address.province ][  this.address.city ].code)
					}];
					return parseInt(TextToCode[ this.address.province ][ this.address.city ].code);
				} else if ( this.address.province != "" && this.address.city != "" && this.address.district != "" ) {				//区
					this.parentInfo = [...this.parentInfo, {
						cityName: this.address.province,
						code: parseInt(TextToCode[ this.address.province ].code)
					}, {
						cityName: this.address.city,
						code: parseInt(TextToCode[ this.address.province ][ this.address.city ].code)
					}, {
						cityName: this.address.district,
						code: parseInt(TextToCode[ this.address.province ][ this.address.city ][ this.address.district ].code)
					}];
					this.address = {
						province : this.address.province,
						city : this.address.city,
						district : this.address.district,
					}
					return parseInt(TextToCode[ this.address.province ][ this.address.city ][ this.address.district ].code);
					//如果是区县级别用户也需要获取它父级的所有城市在遍历出自己进行渲染
				}
				return 100000;
			},
			getGeoJson(adcode) {
				if( this.parentInfo.length == 4 ){
					this.showMap = true;
					this.getData( this.address );
					return;
				}
				//根据登录信息换取code和设置顶部
				let that = this;
				if( !AMapUI ){
					window.reload();
				}
				AMapUI.loadUI(["geo/DistrictExplorer"], DistrictExplorer => {
					var districtExplorer = new DistrictExplorer();
					districtExplorer.loadAreaNode( adcode, ( error, areaNode ) => {
						if (error) {
							console.error(error);
							return;
						}
						let Json = areaNode.getSubFeatures();
						if (Json.length > 0) {
							//判断是否是区县级用户如果是区县级用户的话就获取他的父级数据在遍历出它自己
							if ( this.user_info.level == 6 ) {
								that.geoJson.features = Json.filter(item => {
									return item.properties.name == this.user_info.district;
								});
							} else {
								that.geoJson.features = Json;
							}
						} else if (Json.length === 0) {
							that.geoJson.features = that.geoJson.features.filter(
								item => item.properties.adcode == adcode
							);
							if (that.geoJson.features.length === 0) {
								that.geoJson.features = [{
									properties: areaNode._data.geoData.parent.properties
								}];
							};
						}
						that.getMapData();
					});
				});
			},
			//获取数据
			getMapData() {
				let mapData = [];
				this.geoJson.features.forEach( ( item , index ) => {
					this.installList.forEach(install => {
						if (item.properties.name == install.area) {
							mapData.push({
								name: item.properties.name,
								value: [item.properties.center[0], item.properties.center[1],
									install.num
								],
								cityCode: item.properties.adcode,
								data : install,
								index : index,
							});
						}
					});
				});
				mapData = mapData.sort(function(a, b) {
					return a.value[2] - b.value[2];
				});
				//去渲染echarts
				this.initEcharts(mapData)
			},
			initEcharts( mapData ) {
				
				myChart = echarts.init( this.$refs.dotMap );
				echarts.registerMap( 'Map' , this.geoJson ); //注册
				myChart.setOption(
					{
						tooltip: {
							trigger: 'item',
							formatter: p => {
								let installNum = p.data.data.num;
								let onlineRate = p.data.data.onlineRate;
								let standardsRate = p.data.data.standardsRate;
								// return `${p.data.name} 安装数:${installNum}台
								// 		在线率:${onlineRate}%
								// 		达标率:${standardsRate}%`;
								return `${p.data.name} 家庭数:${installNum}户`;
							}
						},
						title: {
							show: false,
							left: "center",
							top: "15",
							text: this.parentInfo[this.parentInfo.length - 1].cityName,
							// text: this.parentInfo[this.parentInfo.length - 1].cityName + "散点图(可点击下钻)",
							textStyle: {
								color: "rgb(179, 239, 255)",
								fontSize: 16
							}
						},
						toolbox: {
							feature: {
								dataView: {
									show: false
								},
								magicType: {
									show: false
								},
								restore: {
									show: false
								},
								saveAsImage: {
									show: false,
									name: '地图',
									pixelRatio: 2
								}
							},
							iconStyle: {
								normal: {
									borderColor: '#1990DA'
								}
							},
							itemSize: 15,
							top: 20,
							right: 22
						},
						geo: {
							top : this.parentInfo.length == 1 ? "30%" : "10%",
							// left:'20%',
							// right:'0',
							// bottom:'0%',
							show: true,
							map: 'Map', //使用
							roam: true,
							itemStyle: {
								normal: {
									show: true,
									areaColor: '#3a7fd5',
									borderColor: '#215495',
									borderWidth: '1.3',
									shadowColor: 'rgb(10,76,139)',
									shadowOffsetX: 5,
									shadowOffsetY: 3,
									shadowBlur: 65
								},
								//emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
								emphasis: {
									show: true,
									areaColor: '#8dd7fc',
								}
							},
							hoverAnimation: true,
							label: {
								normal: {
									show: true,
									color: "rgb(249, 249, 249)", //省份标签字体颜色
									formatter: p => {
										switch (p.name) {
											case "内蒙古自治区":
												p.name = "内蒙古";
												break;
											case "西藏自治区":
												p.name = "西藏";
												break;
											case "新疆维吾尔自治区":
												p.name = "新疆";
												break;
											case "宁夏回族自治区":
												p.name = "宁夏";
												break;
											case "广西壮族自治区":
												p.name = "广西";
												break;
											case "香港特别行政区":
												p.name = "香港";
												break;
											case "澳门特别行政区":
												p.name = "澳门";
												break;
											default:
												break;
										}
										return p.name;
									}
								},
								emphasis: {
									show: true,
									color: "#f70000"
								}
							},
							zoom : this.parentInfo.length == 1 ? "1.64" : "1.15", 
						},
						series: [
							{
								type: 'effectScatter',
								data: mapData,
								coordinateSystem: 'geo',
								//这里可以设置点的大小
								symbolSize: function(val) {
									return 25
								},
								showEffectOn: 'render', //高亮时显示特效
								rippleEffect: {
									brushType: 'fill'
								},
								hoverAnimation: true,
								label: {
									normal: {
										formatter: p => {
											return p.value[2].toFixed()
										},
										show: true,
										textStyle: {
											color: '#E6E6E6'
										}
									},
									emphasis: {
										textStyle: {
											color: '#E6E6E6'
										}
									}
								},
								itemStyle: {
									normal: {
										color: 'rgba(108, 3, 255, 0.8)' //地图点的颜色
									},
								},
								layoutCenter: ['50%', '50%'], //属性定义地图中心在屏幕中的位置，一般结合layoutSize 定义地图的大小
								layoutSize: 430
							}, 
						]
					},
					true
				);
				myChart.on('mousemove', ( e ) => {
				    clearInterval( this.timer );
				    myChart.dispatchAction({
				        type: 'downplay',
				        seriesIndex: 0
				    });
				    myChart.dispatchAction({
				        type: 'highlight',
				        seriesIndex: 0,
				        dataIndex: e.dataIndex
				    });
				    myChart.dispatchAction({
				        type: 'showTip',
				        seriesIndex: 0,
				        dataIndex: e.dataIndex
				    });
				}); //---------------------------------------------鼠标移入静止播放
				myChart.on('mouseout', ( e ) => {
				    this.gaoliang( myChart , mapData );
				});
				//高亮结束
				let that = this;
				myChart.off("click");
				myChart.on("click", params => {
					// return;
					if (!params.data) {
						return
					}
					if (that.parentInfo[that.parentInfo.length - 1].code == params.data.cityCode) {
						return;
					}
					
					let data = params.data;
					// this.$emit( 'change' , );
					this.update([...that.parentInfo, {
						cityName: data.name,
						code: data.cityCode
					}]);
					that.parentInfo.push({
						cityName: data.name,
						code: data.cityCode
					});
					return;
					that.getGeoJson(data.cityCode);
				});
				//高亮
				this.gaoliang( mapData );
			},
			update(parentInfo) {
				let data = {
					province: {
						cityName: "",
						code: ""
					},
					city: {
						cityName: "",
						code: ""
					},
					district: {
						cityName: "",
						code: ""
					},
				}
				if (parentInfo.length == 2) { //省
					data.province = parentInfo[1];
				} else if (parentInfo.length == 3) { //市
					data.province = parentInfo[1];
					data.city = parentInfo[2];
				} else if (parentInfo.length == 4) { //区
					data.province = parentInfo[1];
					data.city = parentInfo[2];
					data.district = parentInfo[3];
				}
				if( parentInfo.length == 4 ){
					let data = {
						province : parentInfo[1].cityName,
						city : parentInfo[2].cityName,
						district : parentInfo[3].cityName,
					}
					this.address = data
					this.getData(data);
					this.showMap = true;
				}else{
					this.showMap = false;
				}
				this.$emit('update', data);
			},
			//选择切换市县
			chooseArea(val, index) {
				if (this.parentInfo.length === index + 1) {
					return
				}
				//区县级用户不能点击面包屑
				if ( this.user_info.level == 6 ) {
					return;
				}
				if ( this.user_info.level == 5 && index < 2 ) {
					return;
				}
				if ( this.user_info.level == 4 && index < 1 ) {
					return;
				}
				this.parentInfo.splice(index + 1);
				this.update(this.parentInfo);
				return;
				this.getGeoJson(this.parentInfo[this.parentInfo.length - 1].code);
			},
			//获取地图数据
			getData( data ){
				this.show_monitoring_info_index = -1;
				this.$api.screenMapList( data ).then( res => {
					if( res.code == 200 ){
						this.list = res.data;
						for( var i = 0 ; i < this.list.length ; i++ ){
							let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
							let x = this.list[i].longitude;
							let y = this.list[i].latitude;
							let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
							let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
							this.list[i].longitude = z * Math.cos(theta) + 0.0065;
							this.list[i].latitude = z * Math.sin(theta) + 0.006;
						}
						this.$refs.qfMap.center = data.district;
						this.$refs.qfMap.zoom = 13;
					}
				});
			},
			//
			show_monitoring( data ){
				this.show_monitoring_flag = true;
				// this.drawer2 = true;
				this.homeData = data;
				this.show_monitoring_info_index  = data.id;
				this.homeDeviceList = data.deviceList;
				this.homeFamilyList = data.userList;
			},
			repaireIndex(){
				this.show_monitoring_flag = false;
			}
			
		},
		components:{
			qfMap
		},
		props: {
			installList: {
				type: Array,
				default: () => {
					return [];
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.echarts {
		width: 100%;
		height: 100%;
		position: relative;
		// background: url("../../../assets/bg_3.jpg") no-repeat;
		background-size: 100% 100%;
	}

	.mapChoose {
		position: absolute;
		left: 20px;
		top: 55px;
		color: #eee;
		display: flex;
		flex-direction: row;

		.title {
			// padding: 5px;
			// border-top: 1px solid rgba(147, 235, 248, .8);
			// border-bottom: 1px solid rgba(147, 235, 248, .8);
			cursor: pointer;
		}

		.icon {
			font-family: "simsun";
			font-size: 20px;
			margin: 0 11px;
		}
	}
	.monitoring_box{
		width: 250px;
		position: absolute;
		height: calc( 100% - 115px );
		z-index: 999;
		top: 120px;
		left: 20px;
		transition: .3s all;
		overflow: hidden;
		opacity: 0.8;
	}
	.monitoring_box2{
		width: 250px;
		position: absolute;
		height: calc( 100% - 115px );
		z-index: 999;
		top: 120px;
		right: 20px;
		transition: .3s all;
		overflow: hidden;
		opacity: 0.8;
	}
	.monitoring{
		background-color: #FFFFFF;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
		.titlel{
			text-align: center;
			position: relative;
			height: 45px;
			display: flex;
			align-items: center;
			text-indent: 1em;
			font-weight: bold;
			letter-spacing: 1px;
			color: #FFFFFF;
			transition: .3s all;
			i{
				position: absolute;
				right: 10px;
				top: 15px;
				cursor: pointer;
			}
		}
		.titlel.bg_0{
			background-color: #FFE266;
		}
		.titlel.bg_1{
			background-color: #67C23A;
		}
		.titlel.bg_2{
			background-color: #F56C6C;
		}
		.titlel.bg_3{
			background-color: #909399;
		}
		.titlel.bg_4{
			background-color: #333333;
		}
		.titlel.bg_5{
			background-color: #28477F;
		}
		.titlel.bg_null{
			background-color: #409EFF;
		}
		.data_list{
			.data_list_item{
				border-top: 1px solid #28477F;
				display: flex;
				flex-direction: row;
				font-size: 12px;
				padding: 10px 0px;
				padding-left: 5px;
				color: #ffffff;
				align-content: center;
				.p{
					// display: flex;
					// align-items: center;
					font-weight: bold;
					line-height: 24px;
				}
				.icon{
					display: flex;
					align-items: center;
					margin-right: 10px;
					img{
						width: 25px;
						height: 25px;
					}
				}
			}
		}
		.but{
			display: flex;
			justify-content: space-between;
			padding: 0 10px;
			padding-bottom: 15px;
		}
		.meun{
			display: flex;
			flex-direction: row;
		}
		.meun_item{
			width: 20%;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .2s all;
			padding: 6px;
			&:hover{
				background-color: #a2a2a2;
			}
		}
	}
</style>
