<template>
	<!-- 达标率TOP5 -->
	<div style="height: 100%;" ref="install"></div>
</template>

<script>
	import * as echarts from 'echarts';
	var chart = null;
	export default{
		data(){
			return {
				chart : "",
			}
		},
		mounted(){
			// this.init();
		},
		methods:{
			dispose(){
				if( chart != null ){
					chart.dispose();
					chart = null;
				}
			},
			init( data ){
				chart = echarts.init( this.$refs.install );
				let yList = data.y;
				let maxData = [];
				let xList =  data.x;
				let color = ['#CC1CAA', '#8D67FF', '#00FFFF', '#48DE13', '#FFC516', '#DC3E14', '#8E16F8'];
				let xData = xList.map((item, index) => {
				    return {
				        value: item,
				        textStyle: {
				            color: '#FCFEFD',
				        },
				    };
				});
				let dom = 300;
				let barWidth = dom / 20;
				let colors = [
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#FF3FDA',
				            },
				            {
				                offset: 0.5,
				                color: '#FF3FDA',
				            },
				            {
				                offset: 0.5,
				                color: '#CC1BAA',
				            },
				            {
				                offset: 1,
				                color: '#CC1BAA',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#602CFF',
				            },
				            {
				                offset: 0.5,
				                color: '#602CFF',
				            },
				            {
				                offset: 0.5,
				                color: '#4915E6',
				            },
				            {
				                offset: 1,
				                color: '#4915E6',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#00FFFF',
				            },
				            {
				                offset: 0.5,
				                color: '#00FFFF',
				            },
				            {
				                offset: 0.5,
				                color: '#11A6D0',
				            },
				            {
				                offset: 1,
				                color: '#11A6D0',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#89FF5E',
				            },
				            {
				                offset: 0.5,
				                color: '#89FF5E',
				            },
				            {
				                offset: 0.5,
				                color: '#48DD12',
				            },
				            {
				                offset: 1,
				                color: '#48DD12',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#FFD05C',
				            },
				            {
				                offset: 0.5,
				                color: '#FFD05C',
				            },
				            {
				                offset: 0.5,
				                color: '#DEA821',
				            },
				            {
				                offset: 1,
				                color: '#DEA821',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#FF7853',
				            },
				            {
				                offset: 0.5,
				                color: '#FF7853',
				            },
				            {
				                offset: 0.5,
				                color: '#DB3E13',
				            },
				            {
				                offset: 1,
				                color: '#DB3E13',
				            },
				        ],
				    },
				    {
				        type: 'linear',
				        x: 0,
				        x2: 1,
				        y: 0,
				        y2: 0,
				        colorStops: [
				            {
				                offset: 0,
				                color: '#AA48FF',
				            },
				            {
				                offset: 0.5,
				                color: '#AA48FF',
				            },
				            {
				                offset: 0.5,
				                color: '#8E15F8',
				            },
				            {
				                offset: 1,
				                color: '#8E15F8',
				            },
				        ],
				    },
				];
				let option = {
				    //提示框
				    /**区域位置*/
					grid: {
						left: '0%',
				        right: '0',
				        top: 20,
				        bottom: 18,
				    },
				    //X轴
				    xAxis: {
				        nameTextStyle: {
				            color: '#FFFFFF',
				            padding: [0, 0, 0, 20],
				        },
				        data: xData,
				        show: true,
				        type: 'category',
				        axisLine: {
				            show: false,
				        },
				        axisLabel: {
				            rotate: 0,
				            fontSize: 12,
							textStyle: {
							    fontSize: 12,
							    color: '#209DF8',
							}
				        },
				    },
				    yAxis: {
				        splitLine: {
				            show: false
				        },
				        axisTick: {
				            show: false
				        },
				        axisLine: {
				            show: false
				        },
				        axisLabel: {
				            show: false
				        }
				    },
				    series: [
				        {
				            type: 'bar',
				            barWidth: barWidth,
				            itemStyle: {
				                normal: {
				                    color: function (params) {
				                        return colors[params.dataIndex % 7];
				                    },
				                },
				            },
				            label: {
				                show: true,
				                position: [barWidth / 2, -(barWidth + 5)],
				                color: '#ffffff',
				                fontSize: 12,
				                fontStyle: 'bold',
				                align: 'center',
								formatter: p => {
									return `${p.value}台`
								}
				            },
				            data: yList,
				        },
				        {
				            data: maxData,
				            type: 'bar',
				            barMaxWidth: 'auto',
				            barWidth: barWidth,
				            barGap: '-100%',
				            zlevel: -1,
				        },
				        {
				            z: 2,
				            type: 'pictorialBar',
				            data: yList,
				            symbol: 'diamond',
				            symbolOffset: [0, '50%'],
				            symbolSize: [barWidth, barWidth * 0.5],
				            itemStyle: {
				                normal: {
				                    color: function (params) {
				                        return colors[params.dataIndex % 7];
				                    },
				                },
				            },
				        },
				        {
				            z: 3,
				            type: 'pictorialBar',
				            symbolPosition: 'end',
				            data: yList,
				            symbol: 'diamond',
				            symbolOffset: [0, '-50%'],
				            symbolSize: [barWidth, barWidth * 0.5],
				            itemStyle: {
				                normal: {
				                    borderWidth: 0,
				                    color: function (params) {
				                        return colors[params.dataIndex % 7].colorStops[0].color;
				                    },
				                },
				            },
				        },
				        
				        {
				            z: 3,
				            type: 'pictorialBar',
				            symbolPosition: 'end',
				            data: maxData,
				            symbol: 'diamond',
				            symbolOffset: [0, '-50%'],
				            symbolSize: [barWidth, barWidth * 0.5],
				            itemStyle: {
				                normal: {
				                    borderWidth: 0,
				                    // color: function (params) {
				                    //     return colors[params.dataIndex % 7].colorStops[0].color;
				                    // },
				                },
				            },
				        },
				    ],
				};
				chart.setOption( option );
			},
			set_option( data ){
				let xAxisData = [];
				let series_data = [];
				data.forEach( ( item , i ) => {
					if( i < 6 ){
						xAxisData.push( item.area );
						series_data.push( item.installNum );
					}
				});
				if( chart == null ){
					this.init( { x : xAxisData , y : series_data } );
					return;
				}
				let option = {
					xAxis: {
						data : xAxisData,
					},
					series: [ 
						{ data : series_data } , 
						{ data : series_data } , 
						{ data : series_data } , 
						{ data : series_data } , 
					]
				}
				chart.setOption( option );
			}
		},
		props:{
			chart_data : {
				type : Array
			}
		}
	}
</script>

<style>
</style>
