<template>
	<!-- 充值方式 -->
	<div style="height: 100%;" ref="install" id="useGasAnalyse"></div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
			}
		},
		mounted() {
			// this.unit();
		},
		methods: {
			set_option(data) {
				this.unit(data)
				var that = this;
			},
			unit(datas) {
				// console.log( datas )
				var myChart = echarts.init(document.getElementById('useGasAnalyse'));
				var color = ['#ffe000', '#b4b5aa', '#0bb6ff', '#00cfff', '#da0d0d', '#0ddc3e']
				// datas[0].installNum = 5;//在线
				// datas[1].installNum = 1;//离线
				// datas[2].installNum = 2;//其他
				// datas[3].installNum = 2;//开机
				// datas[4].installNum = 1;//关机	
				var option = {
					silent: true,
					series: [{
						radius: ['15%', '100%'],
						type: 'sunburst',
						sort: undefined,
						emphasis: {
							focus: 'ancestor'
						},
						data: [
							{
								value: datas[0].installNum + datas[1].installNum + datas[2].installNum + datas[3].installNum + datas[4].installNum,
								children: [{
										value: datas[2].installNum,
										name: '其他' +datas[2].installNum + '台',
										itemStyle: {
											color: color[0]
										},
									},
									{
										value: datas[1].installNum,
										name: '离线' + datas[1].installNum + '台',
										itemStyle: {
											color: color[1]
										},
									},
									{
										value: datas[0].installNum + datas[3].installNum + datas[4].installNum,
										name: '在线' + ( datas[0].installNum + datas[3].installNum + datas[4].installNum ) + '台',
										itemStyle: {
											color: color[2]
										},
										children: [{
												value: datas[3].installNum,
												name: '开机' + datas[3].installNum + '台',
												itemStyle: {
													color: color[5]
												},
											},
											{
												value: datas[4].installNum,
												name: '关机' + datas[4].installNum + '台',
												itemStyle: {
													color: color[4]
												},
											}]
									}
								]
							}
						],
						label: {
							color: '#000',
							textBorderColor: '#fff',
							textBorderWidth: 2,
							formatter: function(param) {
								var depth = param.treePathInfo.length;
								// return '';
							}
						},
						levels: [{},
							{
								label: {
									rotate: 'radial'
								}
							},
							{
								label: {
									rotate: 'tangential'
								}
							},
							{
								label: {
									rotate: 0
								}
							}
						]
					}]
				};
				myChart.setOption(option)
			}
		}
	}
</script>

<style>
</style>