<template>
	<baidu-map :center="center" :zoom="zoom" :scroll-wheel-zoom="true" ref="map" id="map" :mapStyle=" map_style ? mapStyle : {} " ak="IO5nbnGLk1HrKOLIxvpcDZQDnGhPjmvi" class="bm-view"
             @zoomend="zoomend">
		<bm-panorama v-if="panorama" :offset="{ width : 10 , height: 50 }" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-panorama>
		<bm-city-list v-if="city_list" :offset="{ width : 10 , height: 110 }" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
		<bm-overview-map v-if="overview" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :isOpen="true"></bm-overview-map>
		<!-- animation="BMAP_ANIMATION_BOUNCE" -->
		<bm-marker
			@click="show_monitoring( i , item )"
			v-for="( item , i ) in list" :key="i"
			:position="{ lng: item.longitude, lat: item.latitude}"
			:icon="{ url: require( '@/assets/address2.png' ) , size: { width: 30 , height: 30 } }">
			<bm-label :content="item.name" :labelStyle="{color: 'red', fontSize : '13px'}" :offset="{ width : 50 , height: 0 }" @click="showPicker( i , item )" style="z-index: 1000;"/>
		</bm-marker>
	</baidu-map>
</template>
<script>

  const BaiduMap = () => import('vue-baidu-map/components/map/Map.vue'); // 导入需要异步加载的组件文件路径
  const BmlMarkerClusterer=()=>  import('vue-baidu-map');

	export default{
		created(){
			if( this.user_info.level == 4 ){
				// Point = this.user_info.province == "" ? Point : this.user_info.province;
				this.center = this.user_info.province;
				this.zoom = 8;
			}else if( this.user_info.level == 5 ){
				this.center = this.user_info.city;
				this.zoom = 10;
			}else if( this.user_info.level > 5 ){
				this.center = this.user_info.district;
				this.zoom = 13;
			}
		},
		data(){
			return {
				center : { lng: 106.533544 , lat: 34.954457 },
				zoom : 6,
				mapStyle: {
						//地图自定义样式
				            styleJson:[{
				                "featureType": "water",
				                "elementType": "all",
				                "stylers": {
									"visibility": "off"
				                }
				            }, {
				                "featureType": "land",
				                "elementType": "all",
				                "stylers": {
				                    "color": "#141D39"
				                }
				            }, {
				                "featureType": "boundary",
				                "elementType": "geometry",
				                "stylers": {
				                    "color": "#064f85"
				                }
				            }, {
				                "featureType": "railway",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "highway",//高速
				                "elementType": "geometry",
				                "stylers": {
									"visibility": "off"
				                }
				            }, {
				                "featureType": "highway",
				                "elementType": "geometry.fill",
				                "stylers": {
				                    "color": "#27467d",
				                    "lightness": 1
				                }
				            }, {
				                "featureType": "highway",
				                "elementType": "labels",
				                "stylers": {
				                    "visibility": "on"
				                }
				            }, {
				                "featureType": "arterial",
				                "elementType": "geometry",
				                "stylers": {
				                    "color": "#004981",
				                    "lightness": -39
				                }
				            }, {
				                "featureType": "arterial",
				                "elementType": "geometry.fill",
				                "stylers": {
				                    "color": "#00508b"
				                }
				            }, {
				                "featureType": "poi",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "green",
				                "elementType": "all",
				                "stylers": {
				                    "color": "#056197",
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "subway",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "manmade",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "local",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "arterial",
				                "elementType": "labels",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "boundary",
				                "elementType": "geometry.fill",
				                "stylers": {
				                    "color": "#029fd4",
									"visibility": "off"
				                }
				            }, {
				                "featureType": "building",
				                "elementType": "all",
				                "stylers": {
				                    "color": "#1a5787"
				                }
				            }, {
				                "featureType": "label",
				                "elementType": "all",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }, {
				                "featureType": "poi",
				                "elementType": "labels.text.fill",
				                "stylers": {
				                    "color": "#ffffff"
				                }
				            }, {
				                "featureType": "poi",
				                "elementType": "labels.text.stroke",
				                "stylers": {
				                    "color": "#1e1c1c"
				                }
				            }, {
				                "featureType": "administrative",
				                "elementType": "labels",
				                "stylers": {
				                    "visibility": "on"
				                }
				            }, {
				                "featureType": "road",
				                "elementType": "labels",
				                "stylers": {
				                    "visibility": "off"
				                }
				            }]
				        },
			}
		},
		methods:{
			show_monitoring( i ){
				var marker = this.list[i];
				this.user_info.level = 1;
				clearInterval(this.ggztime)
				var THIS = this
				this.ggztime = setInterval(function() {
					THIS.zoom = 18;
					THIS.center={
						"lng":marker.longitude,
						"lat":marker.latitude
					}
				}, 3000)
				// this.$emit( 'show_monitoring' , marker );
			},
			showPicker( i ){
				var marker = this.list[i];
				this.$emit( 'show_monitoring' , marker );
			},
			changeMap( data ){
				if( data.province == undefined ){
					data.province = ""
				}
				if( data.city == undefined ){
					data.city = ""
				}
				if( data.district == undefined ){
					data.district = ""
				}
				if( data.province != "" && data.city == "" && data.district == "" ){
				// Point = this.user_info.province == "" ? Point : this.user_info.province;
				this.center = data.province;
				this.zoom = 8;
				}else if( data.province != "" && data.city != "" && data.district == "" ){
					this.center = data.city;
					this.zoom = 10;
				}else if( data.province != "" && data.city != "" && data.district != "" ){
					this.center = data.district;
					this.zoom = 13;
				}else{
					this.zoom = 6;
					this.center = { lng: 106.533544 , lat: 34.954457 }
				}
			},
			changeFc( indexf , ig ){
				var ed = this.list[indexf];
				ed.status = this.list[indexf].deviceList[ig].status;
				this.list.splice( indexf , 1 );
				this.list.splice( indexf , 0 , ed );
			},
      zoomend(e) {
        var zoom = e.target.getZoom();
        if (zoom < 18) {
          clearInterval(this.ggztime);
          this.level = 0;
          this.zoom = zoom
        } else {
          this.zoom = 18
        }
      }
		},
		props:{
			list : {
				default: () => {
					return [];
				},
				type : Array,
			},
			map_style : {
				default:  false,
				type : Boolean
			},
			panorama : {
				default:  true,
				type : Boolean
			},
			city_list : {
				default:  true,
				type : Boolean
			},
			overview : {
				default:  true,
				type : Boolean
			},

		},
		components:{
			BaiduMap,BmlMarkerClusterer
		}
	}
</script>

<style>
	#map{
		height: 100%;
		width: 100%;
	}
	.anchorBL,.anchorBL{
		display: none;
	}
</style>
